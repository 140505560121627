






































/* eslint-disable */
import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'
import DatepickerRecord from '@/components/DatepickerRecord.vue'
import moment from 'moment'

export default defineComponent({
  name: 'step-two',
  components: {
    DatepickerRecord,
  },
  props: {
    parentData: {
      type: Object,
      required: true,
    },
  },

  setup(_props, ctx) {
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n, i18nSuivi } = useState('my', ['i18n', 'i18nSuivi'])
    const date = ref('')
    const TWO = ref('2')
    const TWENTYONE = ref('21')
    const TWENTYTWO = ref('22')
    const TWENTYTHREE = ref('23')
    const { currentLang } = useGetters(['currentLang'])

    if (
      _props.parentData.codeStatut === TWENTYTWO.value ||
      _props.parentData.codeStatut === TWENTYTHREE.value
    ) {
      for (let i in _props.parentData.liste) {
        if (_props.parentData.liste[i].codeInfo === '100_1') {
          date.value = moment(_props.parentData.liste[i].valeurInfo)
            .locale(currentLang.value)
            .format('DD MMMM YYYY')
        }
      }
    }
    const processHtml = (text: string) => {
      if (date.value === '') date.value = 'inconnu.'
      let html = text.replace('%datePlan', date.value)
      let htmlDate = text.replace('%date', date.value)
      if (htmlDate) return htmlDate
      return html
    }
    const processFluide = (text: string, fluide: string) => {
      if (fluide === 'Elec') {
        let html = text.replace(
          '%typeFluide',
          i18nSuivi.value.stepTwoInstructionTextElec
        )
        return html
      } else {
        let html = text.replace(
          '%typeFluide',
          i18nSuivi.value.stepTwoInstructionTextGaz
        )
        return html
      }
    }
    //ScrollTop
    ctx.root.$nextTick(() => {
      const el = document.querySelector('.step-two')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    })
    return {
      user,
      chromeI18n: chrome.value.data.i18n,
      i18n,
      date,
      i18nSuivi,
      TWO,
      TWENTYTHREE,
      TWENTYONE,
      TWENTYTWO,
      processHtml,
      processFluide,
      currentLang,
    }
  },
})
